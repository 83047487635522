<template>
  <div style="background-color: white">
    <div class="header">
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <img :src="logo" class="logo" />
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li v-for="(item, index) in links" :key="index" :class="{ active: item.active }">
              <router-link :to="item.url" :class="{ active: item.url === '/coursedetail' }">{{ item.name }}
              </router-link>
            </li>
          </ul>
          <Avatar :ref="(el) => avatarRef = el" style="margin-right: 37px" />
        </div>
      </div>
    </div>
    <div style="padding: 1px 0">
      <div class="wrap">
        <div class="article-wrap flex-row">
          <div class="article-detail flex1">
            <h3 v-text="data.articleName"></h3>
            <div class="flex-row">
              <div class="article-info">
                <div class="year" v-text="data.year"></div>
                <div class="day" v-text="data.day"></div>
                <div
                  class="collect"
                  @click="collect(data.id)"
                  :style="`background:${data.collectionState === 1 ? '#ffd18c' : '#ddd'};`"
                >
                  <img src="../assets/collArticle.png" />
                </div>
              </div>
              <div class="v-line"></div>
              <div :aid="data.id" class="flex1 ck-content ck ck-editor"
                v-html="data.articleContent && data.articleContent.content" style="margin: 0px 1rem 0 1.2rem"></div>
            </div>
          </div>
          <div style="width: 20em">
            <div class="articles">
              <div v-for="(item, index) in articles" :key="index" class="article flex-row" @click="toTop">
                <div class="time-line flex-column flex-center">
                  <div class="time-line-dot"></div>
                  <div class="time-line-line"></div>
                </div>
                <router-link :to="($route.params.zone || 1) === 1 ? '/article/' + item.id:`/zarticle/${$route.params.zone}/${item.id}`" class="content">
                  <div class="day" v-text="item.day"></div>
                  <div class="flex-column">
                    <div class="ellipsis2" style="font-weight: bold" v-text="item.articleName"></div>
                  </div>
                </router-link>
              </div>
              <router-link to="/trend" class="button-more text-center">查看更多</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Warning -->
    <div v-if="warningMsg" class="div-warn">
      <div class="warning">
        <span>{{ msg }}</span>
      </div>
    </div>
  </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import "swiper/css/swiper.min.css";
import { post, getLogindata, ajax } from "@/utils/common";
import { login } from "@/components/Login";
const logo = require("../assets/logo.jpg");
import moment from "moment";
import newid from "../utils/key";
import { getTime, saveTime } from "../utils/player-time";

@Options({
  components: {},
})
export default class Home extends Vue {
  avatarRef = "";
  logo = logo;
  data = {};
  articles = [];
  warningMsg = false;
  msg = "";
  mounted() {
    this.load();
  }

  selectcategory(e) {
    this.category_index = Number(e.currentTarget.dataset.index);
  }

  selectorder(e) {
    this.order_index = Number(e.currentTarget.dataset.index);
  }

  size = 4;

  load() {
    // // web_platform/getArticleDetail  文章详情
    post(
      `web_platform/getArticleDetail?zone=${this.$route.params.zone || 1}`,
      { id: this.$route.params.id },
      (data, res) => {
        const item = (data && data.data) || {};
        item.cover = item.imgUrl ? JSON.parse(item.imgUrl)[0] : "";
        const date = item.webPublishTime ? moment(item.webPublishTime) : null;
        this.data = {
          ...item,
          year: date && date.format("YYYY"),
          day: date && date.format("MM.DD"),
        };

        setTimeout(() => {
          const wrap = document.querySelector(`.ck-content[aid='${item.id}']`);
          wrap.querySelectorAll("oembed[url]").forEach((item) => {
            const cover = item.getAttribute("url");
            let attr = cover.substring(cover.lastIndexOf("?") + 1);
            let params = {};
            attr &&
              attr.split("&").forEach((part) => {
                const [key, value] = part.split("=");
                params[key] = value ? decodeURIComponent(value) : value;
              });
            const id = params.vid;
            console.log(id, cover);

            const video_dom_id = newid();
            const insertDom = document.createElement("div");
            insertDom.setAttribute("id", video_dom_id);
            // insertDom.innerHTML = `<img src="${cover}" style="width: 100%;height: 100%;object-fit: cover;display: block;" />`;
            item.parentElement.insertBefore(insertDom, item);
            insertDom.style.height =
              (insertDom.getBoundingClientRect().width / 16) * 9 + "px";

            // 查询素材库，获取视频地址
            ajax({
              url: "course/selectMaterialLibraryById",
              type: "post",
              data: {
                id,
              },
              success: ({ data = {} }, res) => {
                this.initPlayer({
                  url: data.videoUrl,
                  id: video_dom_id,
                  cover,
                  vid: id,
                });
              },
              silence: true,
            }).catch((err) => { });
          });
        });

        if (item.id) {
          post(
            `web_platform/listPlatformArticle`,
            {
              page: { from: 0, size: this.size + 1 },
              params: { partId: item.partId },
            },
            (data, res) => {
              const list =
                (data && data.data).filter(
                  (article) => item.id !== article.id
                ) || [];
              this.articles = list.slice(0, this.size).map((item) => {
                item.day = item.webPublishTime
                  ? moment(item.webPublishTime).format("YYYY.MM.DD")
                  : null;
                return item;
              });
            }
          );
        }
      }
    );
  }

  players = {};

  initPlayer({ id, url, cover, vid }) {
    if (this.players[id]) {
      this.players[id].dispose();
    }
    this.players[id] = new Aliplayer(
      {
        id,
        source: url,
        width: "100%",
        height: "99%",
        cover,
        autoplay: false,
        isLive: false,
        components: [
          {
            name: "MemoryPlayComponent",
            type: AliPlayerComponent.MemoryPlayComponent,
            args: [
              false,
              () => {
                return getTime(vid);
              },
              (url, time) => {
                saveTime(vid, time);
              },
            ],
          },
        ],
        skinLayout: [
          {
            name: "bigPlayButton",
            align: "blabs",
            x: 30,
            y: 80,
          },
          {
            name: "H5Loading",
            align: "cc",
          },
          {
            name: "errorDisplay",
            align: "tlabs",
            x: 0,
            y: 0,
          },
          {
            name: "infoDisplay",
          },
          {
            name: "tooltip",
            align: "blabs",
            x: 0,
            y: 56,
          },
          {
            name: "thumbnail",
          },
          {
            name: "controlBar",
            align: "blabs",
            x: 0,
            y: 0,
            children: [
              {
                name: "progress",
                align: "blabs",
                x: 0,
                y: 44,
              },
              {
                name: "playButton",
                align: "tl",
                x: 15,
                y: 12,
              },
              {
                name: "timeDisplay",
                align: "tl",
                x: 10,
                y: 7,
              },
              {
                name: "fullScreenButton",
                align: "tr",
                x: 10,
                y: 12,
              },
              // {
              //   name: "setting",
              //   align: "tr",
              //   x: 15,
              //   y: 12,
              // },
              {
                name: "volume",
                align: "tr",
                x: 5,
                y: 10,
              },
            ],
          },
        ],
      },
      (player) => {
        console.log("player ===>", player);
      }
    );
  }

  beforeUnmount() {
    for (let key in this.players) {
      this.players[key].dispose();
      this.players[key] = null;
    }
  }

  //收藏文章
  collect(id) {
    let _this = this;
    let isLogin = getLogindata() || {};
    if (!isLogin.headImg) {
      login((logon) => {
        if (logon) {
            _this.avatarRef.LoginStatus(false);
          post(
            "collection/addRemoveCollection",
            { collectionType: 1, sourceId: id },
            (res) => {
              this.data.collectionState = res.data;
            }
          );        
          if (this.data.collectionState === 1) {
            console.log('shoucangcglm',this.data.collectionState);
             _this.warningMsg = true;
            _this.msg = "已取消收藏";
            setTimeout(function () {
              _this.warningMsg = false;
            }, 2000);
            return;
          } else if (this.data.collectionState === 0){
            console.log('quxiaoshoucanglm',this.data.collectionState);
            _this.warningMsg = true;
            _this.msg = "收藏成功";
            setTimeout(function () {
              _this.warningMsg = false;
            }, 2000);
            return;
          }
        }
      });
      return;
    }
    post(
      "collection/addRemoveCollection",
      { collectionType: 1, sourceId: id },
      (res) => {
        this.data.collectionState = res.data;
      }
    );
    if (this.data.collectionState === 1) {
      console.log('shoucangcglm',this.data.collectionState);
      _this.warningMsg = true;
      _this.msg = "已取消收藏";
      setTimeout(function () {
        _this.warningMsg = false;
      }, 2000);
      return;
    } else if (this.data.collectionState === 0){
      console.log('quxiaoshoucanglm',this.data.collectionState);
      _this.warningMsg = true;
      _this.msg = "收藏成功";
      setTimeout(function () {
        _this.warningMsg = false;
      }, 2000);
      return;
    }
  }
  toTop() {
    document.documentElement.scrollTop = 0;
  }
}

</script>

<style scoped lang="less">
.header {
  position: sticky;
  top: 0;
  z-index: 20;
}

.article-wrap {
  margin: 3rem;
}

.article-detail {
  .cover {
    width: 400px;
    height: 240px;
    object-fit: cover;
  }

  h3 {
    margin: 1.5em 0;
    font-size: 1.6rem;
  }

  .v-line {
    width: 1px;
    background-color: #dddddd;
    margin: 0 1rem;
    align-self: stretch;
  }

  .article-info {
    color: #999999;
    text-align: center;

    .year {
      font-weight: bold;
    }

    .day {
      font-weight: bold;
      font-size: 1.8rem;
      width: 3.2em;
    }
  }
}

.articles {
  margin: 3rem 0 0 0;
  background-color: white;
  position: sticky;
  top: 7rem;

  .article {
    .time-line {
      width: 3rem;

      .time-line-dot {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        box-sizing: border-box;
        border: 3px solid #ccc;
      }

      .time-line-line {
        width: 2px;
        flex: 1;
        background-color: #ccc;
      }
    }

    .content {
      flex: 1;
      width: 10px;
      margin: -0.2em 0 1.2em 0;
      color: #666;

      .day {
        color: #a5a5a5;
      }
    }
  }
}

.button-more {
  margin: 2rem 0 0 1.5rem;
  line-height: 3rem;
  border-radius: 1.5rem;
  border: 1px solid #dfdfdf;
  color: #f39801;
  display: block;
}

//收藏
.collect {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ddd;
  margin: 10px 0 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 35px;
  }
}
.collect:hover{
    cursor: pointer;
  }
//收藏后提示
.div-warn {
  position: absolute;
  width: 150px;
  height: 20px;
  top: 45%;
  left: 38%;
  color: #fff;
  font-size: 14px;
  background: rgba(76, 76, 76, .8);
  z-index: 100;
  border-radius: 4px;
  text-align: center;
  padding: 4vh 1vw;
  letter-spacing: 4px;
 
}
</style>