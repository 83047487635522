import { load, save } from "@/utils/storage";

const key = 'vds';

const times: {
    [id: string]: [number, number]
} = {};

const videos: [[number, number]] = load(key) || [];

videos.forEach(item => {
    times[item[0]] = item;
})

export function getTime(id: number) {
    return times[id] && times[id][1];
}

const max_size = 1000;

export function saveTime(id: number, time: number) {
    if (times[id]) {
        times[id][1] = time;
    } else {
        videos.push(times[id] = [id, time]);
        if (videos.length > max_size) {
            const first = videos.shift();
            first && delete times[first[0]];
        }
    }
    save(key, videos);
}
